import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box, Alert } from '@mui/material';
import Header from '../component/head';
import { toast } from 'react-toastify';
import DrawerAppBar from './Header';

const Login: React.FC = () => {
    const [licenseKey, setLicenseKey] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<string>('');
    const navigate = useNavigate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const key = searchParams.get('key');

    useEffect(() => {
        if (key !== null)
            setLicenseKey(key)
    }, [key])



    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        try {
            axios.post("https://ttsbackend.daddyhelp.me/validate_license", {
                license_key: licenseKey,
                email: email
            }).then(() => {
                localStorage.setItem('tts', "true")
                localStorage.setItem('tts-email', email)
                // Redirect to the other screen
                navigate("/");
            }).catch(({ response }) => {
                setError(response.data.detail);
            })
        } catch (e) {
            console.log(e)
        }
    };

    // useEffect(() => {
    //     const isLogin = localStorage.getItem("tts");
    //     if (isLogin === "true") {
    //         navigate("/tts");
    //     }
    // }, []);

    return (
        <Container maxWidth="xs">
            <DrawerAppBar></DrawerAppBar>
            <Header />
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    mt: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#f7f7f7',
                    padding: '24px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
                    Enter License Key
                </Typography>

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="licenseKey"
                    label="License Key"
                    name="licenseKey"
                    autoFocus
                    value={licenseKey}
                    onChange={(e) => setLicenseKey(e.target.value)}
                    sx={{ mb: 2 }}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{ mb: 2 }}
                />
                {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Submit
                </Button>
                {/* <Button
                    onClick={() => {
                        let trail = localStorage.getItem("tts-trail")
                        console.log({ trail })
                        if (trail !== null && JSON.parse(trail) > 3) {
                            alert("You are not able to trail again!")
                            return
                        }
                        if (email == "") {
                            alert("Please fill email")
                        } else {
                            localStorage.setItem("tts-email", email)
                            localStorage.setItem("tts-trail", trail || '0')
                            navigate('/tts')
                        }
                    }}
                    style={{ marginBottom: 10 }}
                    fullWidth
                    variant="outlined"
                    color="secondary"
                >
                    Start Trail Now
                </Button> */}
                <Button
                    onClick={() => navigate("/pricing")}
                    fullWidth
                    variant="outlined"
                    color="secondary"
                >
                    Get License
                </Button>
            </Box>
        </Container>
    );
};

export default Login;

import { Box, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"


const Header = () => {
    const navigate = useNavigate()
    return (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
            <img onClick={() => navigate("/")} style={{ width: 200, cursor: "pointer" }} src="/logo512.png" alt="Logo" />
            <Typography component="p" variant="subtitle2" sx={{ mb: 4, fontWeight: '800' }}>
            Sofia is a lightning-fast text-to-speech tool that brings your words to life with exceptional realism. Whether you're creating natural-sounding voiceovers for  videos, advertisements, night stories or e-learning courses, Sofia has you covered.
            </Typography>
        </Box>
    )
}

export default Header;
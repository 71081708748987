import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Login from './screens/Login';
import Payment from './screens/Payment';
import AudioTool from './screens/AudioTool';

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login></Login>,
  },
  {
    path: "/",
    element: <AudioTool></AudioTool>,
  },
  {
    path: "/pricing",
    element: <Payment></Payment>,
  },
]);


function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;

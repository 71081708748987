import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Container, Box, TextField, Button, MenuItem, Typography, Card, CardContent, IconButton, Autocomplete, CircularProgress, Grid, useMediaQuery, useTheme, Link } from '@mui/material';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import DownloadIcon from '@mui/icons-material/Download';
import languageJSON from '../utils/language.json'
import { generateUniqueKey, getKeyByValue } from '../utils/function';
import axios from 'axios';
import { toast } from 'react-toastify';
import { fetchFile, toBlobURL } from '@ffmpeg/util';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { saveAs } from 'file-saver'; // For saving the combined file
import { useNavigate } from 'react-router-dom';
import DrawerAppBar from './Header';

const AudioTool = () => {
  const navigate = useNavigate()
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const [inputText, setInputText] = useState<string>('');
  const [language, setLanguage] = useState<string>('EN');
  const [audioData, setAudioData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingMerge, setLoadingMerge] = useState<boolean>(false)
  const [audioUrl, setAudioUrl] = useState('');
  const [error, setError] = useState('');
  const [trail, setTrail] = useState<number | null>(null)

  const [loaded, setLoaded] = useState(false);
  const ffmpegRef = useRef(new FFmpeg());
  const isPaid = useMemo(() =>
    localStorage.getItem("tts") == "true"
    , [])

  const load = async () => {
    const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd'
    const ffmpeg = ffmpegRef.current;
    ffmpeg.on('log', ({ message }: any) => {
      console.log(message);
    });
    // toBlobURL is used to bypass CORS issue, urls with the same
    // domain can be used directly.
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
      wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
    });
    setLoaded(true);
  }

  useEffect(() => {
    load()
  }, [])

  useEffect(() => {
    let trail = localStorage.getItem("tts-trail")
    let check = localStorage.getItem("tts")

    if (trail == null) {
      localStorage.setItem("tts-trail", "0")
      localStorage.setItem("tts-userId", generateUniqueKey())
      setTrail(0)
      return
    }

    if (trail == "0" || trail == "1" || trail == "2" || trail == "3") {
      setTrail(JSON.parse(trail))
      return
    } else if (check == "true") {
      return
    } else {

    }

  }, [])



  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (trail === 3 && !isPaid) {
      alert("Your trial period has ended. Please consider upgrading to continue using the service.");
      return;
    }
    setLoading(true)
    let email = localStorage.getItem('tts-email')
    let tr = localStorage.getItem('tts')
    let uID = localStorage.getItem("tts-userId")


    let url = "https://ttsbackend.daddyhelp.me/try_out_tts"
    let body: { text: string; language: string; email: string | null; user_id?: string } = {
      text: inputText,
      language,
      email,
      user_id: uID || ""
    };
    if (tr == "true") {
      url = 'https://ttsbackend.daddyhelp.me/generate_tts'
    }

    // Simulate data fetching
    axios.post(url, body).then(({ data }) => {
      setLoading(false)
      let tempAudio = [...audioData]
      tempAudio.push({
        text: inputText,
        language: language,
        audioUrl: data.file_uri // Replace with actual audio URL
      })

      if (trail !== null && !isPaid) {
        localStorage.setItem("tts-trail", JSON.stringify(trail + 1))
        setTrail(trail + 1)
      }

      setAudioData(tempAudio);
      toast("Success!")
      setInputText('')
    }).catch((e) => {
      setLoading(false)
      console.log(e)
    })

  };

  const handleClear = () => {
    setInputText('');
    setLanguage('');
    setAudioData([]);
  };

  const handleCombineAndDownload = async () => {

    if (!audioData || audioData.length === 0) return;

    // If there's only one audio file, just download it
    if (audioData.length === 1) {
      saveAs(audioData[0].audioUrl, 'audio.wav');
      return;
    }
    setLoadingMerge(true)


    let ffmpeg = ffmpegRef.current;

    const inputs = [];
    const listFileContent = [];

    // Load audio files into FFmpeg
    for (let i = 0; i < audioData.length; i++) {
      const fileName = `input${i}.wav`;
      inputs.push(fileName);
      listFileContent.push(`file '${fileName}'`);

      // Fetch the audio file and write it to FFmpeg's virtual file system
      const fileData = await fetchFile(audioData[i].audioUrl);
      ffmpeg.writeFile(fileName, fileData);
    }

    // Write the list of files to be concatenated into a text file
    ffmpeg.writeFile('list.txt', listFileContent.join('\n'));

    // Run the FFmpeg command to concatenate the audio files
    await ffmpeg.exec(['-f', 'concat', '-safe', '0', '-i', 'list.txt', '-c', 'copy', 'output.wav']);

    // Read the output file and save it
    const data = await ffmpeg.readFile('output.wav');
    const blob = new Blob([data], { type: 'audio/wav' });

    // const url = URL.createObjectURL(blob);

    // Set the URL to state for playback
    // setAudioUrl(url);

    setLoadingMerge(false)
    saveAs(blob, 'combined_audio.wav');

  };


  return (
    <Container maxWidth="lg">
      <DrawerAppBar></DrawerAppBar>
      <Grid sx={{ marginTop: 10 }} container spacing={4}>
        {/* Left Side: Tagline and Description */}
        <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
          <Box
            sx={{
              p: 3,
              borderRadius: 2,
              boxShadow: 1,
              height: '100%',
              background: 'linear-gradient(to right, #6a11cb, #2575fc)', // Gradient background
              color: 'white',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                mb: 2,
                fontWeight: 'bold',
                background: 'linear-gradient(to right, #00bfae, white)', // Adjusted gradient colors
                WebkitBackgroundClip: 'text',
                color: 'transparent',
                backgroundSize: '100% 100%',
                backgroundClip: 'text',
                textFillColor: 'transparent',
              }}
            >
              Your words, Our voice, everything simplified.
            </Typography>

            <Typography
              variant="h6"
              sx={{
                mb: 2,
                fontWeight: 'bold',
                background: 'linear-gradient(to right, #ff7e5f, white)', // Adjusted gradient colors
                WebkitBackgroundClip: 'text',
                color: 'transparent',
                backgroundSize: '100% 100%',
                backgroundClip: 'text',
                textFillColor: 'transparent',
              }}
            >
              Sofia: Your All-in-One Text-to-Speech Solution
            </Typography>
            <Typography variant="body2" sx={{ mb: 2, fontWeight: 'bold' }}>
              Use Sofia for:
            </Typography>
            <ul>
              <li>E-learning</li>
              <li>Audiobooks</li>
              <li>Product demos</li>
              <li>Corporate presentations</li>
              <li>Social media content</li>
              <li>Language learning</li>
              <li>Gaming</li>
              <li>Advertising</li>
              <li>Personal projects</li>
            </ul>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Sofia is a lightning-fast text-to-speech tool that brings your words to life with exceptional realism. Whether you're creating natural-sounding voiceovers for videos, advertisements, night stories, or e-learning courses, Sofia has you covered.
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                Listen to a Sample:
              </Typography>
              <audio controls>
                <source src="/sample.wav" type="audio/wav" />
                Your browser does not support the audio element.
              </audio>
            </Box>
          </Box>

        </Grid>

        {/* Right Side: Form */}
        <Grid item xs={12} md={6}>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              // mt: 4,
              mb: 4,
              p: 3,
              borderRadius: 2,
              boxShadow: 3, // Increased shadow for better visibility
              backgroundColor: '#f9f9f9', // Light background color for contrast
            }}
          >
            <Typography
              variant="h5"
              sx={{
                mb: 3,
                fontWeight: 'bold',
                background: 'linear-gradient(to right, #FF7E5F, #FEB47B)', // Example gradient
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Text-to-Speech Generator
            </Typography>

            <TextField
              label="Enter Text"
              variant="outlined"
              fullWidth
              required
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              multiline
              rows={4} // Adjust the number of rows to control the height
              sx={{
                mb: 2,
                '& .MuiInputBase-root': {
                  fontSize: '1rem', // Increase font size for better readability
                  padding: '10px', // Add padding for a better user experience
                },
                '& .MuiOutlinedInput-root': {
                  // Optional: adjust height if needed
                  minHeight: '80px', // Ensure the textarea has enough height
                },
                '& .MuiInputLabel-root': {
                  fontSize: '1rem', // Increase font size of the label
                },
              }}
            />


            <Box sx={{ width: '100%', mb: 2 }}>
              <Autocomplete
                value={(languageJSON as any)[language]}
                onChange={(e, value) => {
                  // const key = getKeyByValue(languageJSON, value);
                  setLanguage(value.value);
                }}
                options={Object.entries(languageJSON).map(([value, label]) => ({
                  value,
                  label,
                }))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Language"
                    variant="outlined"
                    fullWidth
                    sx={{
                      '& .MuiInputBase-root': {
                        fontSize: '1rem', // Increase font size for better readability
                      },
                    }}
                  />
                )}
              />
            </Box>


            <Button
              type="submit"
              disabled={loading || loadingMerge}
              variant="contained"
              color="primary"
              startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
              sx={{
                mb: 2,
                px: 4, // Increased padding for bigger button
                py: 1.5,
                fontSize: '1rem', // Increase font size
                textTransform: 'none', // Prevent text from being uppercase
              }}
            >
              {loading ? 'Generating...' : 'Generate Speech'}
            </Button>
          </Box>
          {!isPaid && <Typography
            variant="caption"
            sx={{
              mt: 1,
              color: 'gray',
              textAlign: 'center',
              display: 'block',
            }}
          >
            You are currently using the trial version. Upgrade to the lifetime version for just $30 and unlock all features.{' '}
            <Link href="/pricing" sx={{ color: 'primary.main', textDecoration: 'none', fontWeight: 'bold' }}>
              Upgrade Now
            </Link>
          </Typography>}

          {audioData.length > 0 && (
            <Box sx={{ mb: 4 }}>
              {audioData.map(({ text, audioUrl, language }: any, i: number) => (
                <Card key={i} sx={{ mb: 2 }}>
                  <CardContent
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      p: 2,
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        flexGrow: 1,
                        fontSize: '1rem', // Increase font size
                        color: '#333',
                      }}
                    >
                      {text} ({language})
                    </Typography>
                    <audio controls src={audioUrl} style={{ marginRight: '10px' }}></audio>
                    <IconButton
                      disabled={loading}
                      onClick={() => window.open(audioUrl, '_blank')}
                      sx={{ ml: 1 }}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </CardContent>
                </Card>
              ))}
            </Box>
          )}

          {audioData.length > 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
              <Button
                variant="outlined"
                disabled={loading || loadingMerge}
                color="secondary"
                onClick={handleClear}
                sx={{
                  px: 4, // Increased padding for bigger button
                  py: 1.5,
                  fontSize: '1rem', // Increase font size
                }}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                disabled={loading || loadingMerge}
                color="primary"
                onClick={handleCombineAndDownload}
                startIcon={loadingMerge ? <CircularProgress size={20} color="inherit" /> : null}
                sx={{
                  px: 4, // Increased padding for bigger button
                  py: 1.5,
                  fontSize: '1rem', // Increase font size
                }}
              >
                {loadingMerge ? 'Merging...' : 'Merge and Download'}
              </Button>
            </Box>
          )}

          {audioUrl && (
            <Box sx={{ mt: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  mb: 2,
                  fontWeight: 'bold',
                  color: '#333',
                }}
              >
                Combined Audio
              </Typography>
              <audio controls>
                <source src={audioUrl} type="audio/mp3" />
                Your browser does not support the audio element.
              </audio>
            </Box>
          )}
        </Grid>

      </Grid>
    </Container>
  );
};

export default AudioTool;
